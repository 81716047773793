.ItemImagesSmall {
    width: 95px;
    margin-right: 20px;
}

.SmallImg {
    width: 100%;
    height: 95px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SmallImgStyle {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    user-select: none;
    pointer-events: none;
}

.SmallImg+.SmallImg {
    margin-top: 18px;
}

.SmallImgPad {
    min-height: 10px;
    min-width: 6vw;
}

.ItemImageStyle {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

.BigImgSliderBox {
    width: calc(100% - 115px - 325px);
    height: 40vw;
    max-height: 600px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 10px;
        background-color: white;
        pointer-events: none;
        z-index: 1;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 40px;
        background-color: white;
        pointer-events: none;
        z-index: 1;
    }
}

.BigImgSlider {
    padding: 0 80px;
    width: calc(100% - 160px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    .mySwiper {
        width: auto;
        height: auto;
        max-width: 100% !important;
        max-height: 100% !important;
        display: flex !important;
        align-items: center;
    }

    .swiper-wrapper {
        height: 100% !important;
        width: 100% !important;
    }

    .swiper-slide {
        width: 100% !important;
        height: 100% !important;
    }
}

.BigImagesRow {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 0;
}

.BigImg {
    height: 100%;
    width: 100%;
    max-height: 100%;
    padding-right: 60px;
    pointer-events: none;
    user-select: none;
}

.ItemImagesSmall2 {
    display: none;
}

@media (max-width: 1100px) {
    .BigImgSliderBox {
        width: calc(100%);
        height: fit-content;
        max-height: unset;
        border: solid 1px #f2f2f2;
        margin-top: 26px;
        border-radius: 8px;
        overflow: hidden;

        &::before {
            display: none;
        }

        &::after {
            display: none;
        }
    }

    .BigImgSlider {
        padding: 0;
        width: 100%;
        height: fit-content;
    }

    .swiper-wrapper {
        display: flex !important;
        align-items: center;
    }

    .ItemImagesSmall {
        display: none;
    }

    .ItemImagesSmall2 {
        display: flex;
        flex-direction: row;
        margin-top: 24px;
        width: 90vw;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .SmallImg {
        width: calc(10vw - 10px);
        height: calc(10vw - 10px);
        min-width: calc(54px - 10px);
        min-height: calc(54px - 10px);
        padding: 5px;
        border: solid 1px #f2f2f2;
        border-radius: 8px;
    }

    .SmallImg+.SmallImg {
        margin-top: 0;
        margin-left: 24px;
    }
}

@media (max-width: 850px) {
    .ItemImagesSmall2 {
        width: 88vw;
    }

    .SmallImg+.SmallImg {
        margin-left: 8px;
    }
}