.Footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.FooterTop {
    width: 80%;
    max-width: 1360px;
    padding: 30px 10% 44px 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #020202;

    .FooterCol {
        height: 100%;
        justify-content: center;
    }
}

.FooterMid {
    width: 80%;
    max-width: 1360px;
    padding: 0 10% 43px 10%;
    display: flex;
    flex-direction: row;
    color: #020202;
    border-bottom: solid 1px #f1f1f1;
}

.FooterBottom {
    width: 80%;
    max-width: 1360px;
    padding: 39px 10%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #02020280;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.01em;
}

.FooterCol {
    width: 25%;
    display: flex;
    flex-direction: column;

    h3 {
        font-size: 20px;
        font-weight: 600;
        margin: 0;
    }

    .FooterLink {
        color: #02020280;
        font-size: 16px;
        font-weight: 500;
        margin-top: 18px;
        cursor: pointer;
        text-decoration: none;
    }

    .AllBrands {
        color: #3a3a3a;
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
            margin-right: 6px;
        }
    }

    .FooterAppsLinks {
        height: 36px;
        margin-top: 18px;
        display: flex;
        flex-direction: row;
        align-items: center;

        span {
            height: 36px;
            width: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }

        img {
            user-select: none;
            pointer-events: none;
        }
    }
}

.FooterLogo {
    width: 116px;
    cursor: pointer;
}

.FooterApp {
    height: 79px;
    width: calc(100% - 48px);
    padding: 0 24px;
    border: solid 1px #f1f1f1;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    img {
        width: 40px;
        height: 40px;
        margin-right: 12px;
        user-select: none;
        pointer-events: none;
    }

    .AppText {
        display: flex;
        flex-direction: column;
        height: 43px;
    }

    .AppSub {
        font-size: 16px;
        font-weight: 600;
    }

    .AppInfo {
        font-size: 14px;
        color: #02020266;
        font-weight: 600;
    }
}

.FooterRight {
    display: flex;
    flex-direction: row;

    .FooterLink {
        cursor: pointer;
        text-decoration: none;
    }

    .FooterLink+.FooterLink {
        margin-left: 24px;
    }
}

@media (max-width: 1468px) {
    .FooterTop {
        justify-content: space-between;

        .FooterCol {
            width: fit-content;
        }
    }

    .FooterAppCol {
        min-width: 294px;
    }
}

@media (max-width: 1240px) {
    .FooterMid {
        justify-content: space-between;
    }

    .MidFooterCol {
        width: fit-content;
    }
}

@media (max-width: 1110px) {
    .FooterBottom {
        flex-direction: column;
        align-items: center;
    }

    .FooterLeft {
        margin-bottom: 10px;
    }
}

@media (max-width: 850px) {
    .FooterTop,
    .FooterMid,
    .FooterBottom {
        width: 88%;
        padding: 10px 6% 34px 6%;
    }
}

@media (max-width: 786px) {
    .FooterMid {
        // flex-direction: column;
        flex-wrap: wrap;
        border-bottom: unset;
        padding-bottom: 0;
        margin-top: -26px;

        &::after {
            content: '';
            width: 100%;
            height: 1px;
            background-color: #F1F1F1;
            margin-top: 26px;
        }

        // .FooterCol+.FooterCol {
        //     margin-top: 26px;
        // }
    }

    .MidFooterCol {
        width: 50%;
        margin-top: 26px;

        h3 {
            font-size: 14px;
        }

        .FooterLink {
            font-size: 12px;
            margin-top: 12px;
        }
    }

    .FooterBottom {
        font-size: 12px;
        padding-top: 26px;
    }
}

@media (max-width: 710px) {
    .FooterTop {
        flex-direction: column;
        margin-bottom: 8px;
        align-items: flex-start;
        padding-bottom: 0;
    }

    .FooterApp {
        height: 66px;
        padding: 0 14px;
        width: calc(100% - 28px);

        .AppText {
            height: fit-content;
        }

        .AppSub {
            font-size: 13px;
        }

        .AppInfo {
            font-size: 10px;
        }

        img {
            height: 36px;
            width: 36px;
        }
    }

    .FooterAppCol {
        margin-top: 16px;
    }

    .FooterLogo {
        width: auto;
        min-height: 20px;
        height: 5vw;
    }
}

@media (max-width: 620px) {
    .FooterBottom {
        align-items: flex-start;
    }

    .FooterLeft {
        margin-bottom: 26px;
    }

    .FooterRight {
        flex-direction: column;

        .FooterLink {
            margin-left: 0 !important;
        }

        .FooterLink+.FooterLink {
            margin-top: 10px;
        }
    }
}

@media (max-width: 490px) {
    .FooterAppCol {
        width: 100%;
        min-width: unset;
        height: fit-content;
    }

    .FooterApp {
        min-height: fit-content;

        .AppText {
            height: unset;
            min-height: fit-content;
        }
    }

}