.SizeFilter {
    margin-bottom: 48px;
}

.SFSub {
    font-size: 18px;
    font-weight: 600;
    color: #020202;
}

.SFNoBrand {
    display: flex;
    align-items: center;
    margin-top: 16px;

    img {
        width: 18px;
        margin-right: 5px;
    }

    span {
        width: calc(100% - 18px - 5px);
        font-size: 12px;
        font-weight: 500;
        color: #121212;
    }
}

.SFGrid {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 4px;
}

.SFSizeItem {
    margin-top: 18px;
    min-width: 50%;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    user-select: none;

    span {
        display: flex;
        font-size: 16px;
        font-weight: 700;
        color: #020202;
    }
}

.SizeCheck {
    width: 26px;
    height: 26px;
    background-color: #f5f5f5;
    border-radius: 4px;
    margin-right: 12px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
        content: '';
        position: absolute;
        width: 22px;
        height: 22px;
        background-color: #121212;
        border-radius: 2px;
        left: calc(50% - 11px);
        top: calc(50% - 11px);
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    img {
        position: absolute;
        width: 14px;
        display: block;
        opacity: 0;
        transition: opacity 0.3s ease;
    }
}

.CheckedSize {
    img {
        opacity: 1 !important;
    }

    &::before {
        opacity: 1 !important;
    }
}

@media (max-width: 850px) {
    .SFGrid {
        margin-top: -18px;
    }

    .SFSizeItem {
        span {
            font-size: 12px;
        }
    }

    .SizeCheck {
        width: 18px;
        height: 18px;
        min-width: 18px;

        &::before {
            width: 14px;
            height: 14px;
            left: calc(50% - 7px);
            top: calc(50% - 7px);
        }

        img {
            width: 10px;
        }
    }
}