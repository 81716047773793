.RefundContainer {
    display: flex;
    flex-direction: column;

    h4 {
        margin: 0;
    }

    p {
        padding: 0;
        margin: 0;
    }

    img {
        margin-top: 1vw;
        width: 40%;
        align-self: center;
    }
}

@media (max-width: 790px) {
    .RefundContainer {
        h3 {
            font-size: 14px;
        }
        
        h4, p {
            font-size: 12px;
        }

        img {
            margin-top: 2vw;
            width: 100%;
        }
    }
}