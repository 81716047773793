
.Star1,
.Star2,
.Star4,
.Star5,
.Star6,
.Star13 {
    width: 13px;
}

.Star3,
.Star7,
.Star8,
.Star9,
.Star10,
.Star11,
.Star12,
.Star15,
.Star16,
.Star18 {
    width: 8px;
}

.Star14 {
    width: 23px;
}

.Star1 {
    right: 5%;
    top: 8%;
}

.Star2 {
    right: 20%;
    top: 45%;
}

.Star3 {
    right: 30%;
    top: 58%;
}

.Star4 {
    right: 12%;
    top: 68%;
}

.Star5 {
    right: 36%;
    top: 80%;
}

.Star6 {
    right: 45%;
    top: 58%;
}

.Star7 {
    right: 45%;
    top: 82%;
}

.Star8 {
    left: 30%;
    top: 58%;
}

.Star9 {
    left: 27%;
    top: 67%;
}

.Star10 {
    left: 17%;
    top: 70%;
}

.Star11 {
    left: 5%;
    top: 73%;
}

.Star12 {
    left: 6%;
    top: 66%;
}

.Star13 {
    left: 4%;
    top: 55%;
}

.Star14 {
    left: 5%;
    top: 44%;
}

.Star15 {
    left: 4%;
    top: 32%;
}

.Star16 {
    left: 22%;
    top: 16%;
}

.Star17 {
    right: 43%;
    top: 29%;
}

.Star18 {
    left: 46%;
    top: 25%;
}