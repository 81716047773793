.PolicyContainer {
    display: flex;
    flex-direction: column;

    h4 {
        margin: 0;
    }

    p {
        padding: 0;
        margin: 0;
    }
}

@media (max-width: 790px) {
    .PolicyContainer {
        h3 {
            font-size: 14px;
        }
        
        h4, p {
            font-size: 12px;
        }
    }
}