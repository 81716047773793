@use '../../base.scss';

.Fav {
    width: 80%;
    max-width: 1360px;
    padding: 0 10%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.FavSub {
    font-size: 36px;
    font-weight: 700;
}

.MT-32 {
    margin-top: -32px !important;
}

@media (max-width: 850px) {
    .Fav {
        width: 88%;
        padding: 0 6%;
    }

    .FavSub {
        font-size: 20px;
        margin-top: 26px;
    }
}