.ContactsInfo {
    margin-top: 42px;
    min-height: calc(100vh - 200px);

    h2 {
        font-size: 36px;
        font-weight: 700;
        color: #020202;
        margin: 0 0 16px 0;
    }
}

.ContactsTable {
    width: calc(100% - 40px);
    padding: 20px;
    background-color: #F5F5F5;
    border-radius: 8px;
}

.MainContacts {
    display: flex;
    flex-direction: row;
    padding: 7px 7px;
    border-radius: 8px;
    background-color: #121212;
    color: white;
    width: fit-content;
    margin-bottom: 32px;
}

.ContactItem {
    width: 190px;
    padding: 5px 9px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    transition: background-color .2s ease;

    img {
        margin-right: 12px;
    }

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);

        .ContactNickname {
            text-decoration: underline;
        }
    }
}

.ContactItem+.ContactItem {
    margin-left: 10px;
}

.ContactInfo {
    display: flex;
    flex-direction: column;
}

.ContactNickname {
    font-size: 16px;
    font-weight: 600;
}

.ContactRes {
    font-size: 12px;
    font-weight: 500;
    color: #ffffffa6;
}

.AllContacts {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ContactItem2 {
    width: 194px;
    padding: 5px 9px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    transition: background-color .2s ease;

    img {
        margin-right: 12px;
    }

    &:hover {
        background-color: rgba(255, 255, 255, 0.7);

        .ContactNickname2 {
            text-decoration: underline;
        }
    }
}

.ContactNickname2 {
    font-size: 16px;
    font-weight: 600;
    color: #292D32;
}

.ContactRes2 {
    font-size: 12px;
    font-weight: 500;
    color: #292D32a6;
}

@media (max-width: 1350px) {
    .ContactItem2 {
        width: fit-content;
    }
}

@media (max-width: 1230px) {
    .MainContacts {
        margin-bottom: 24px;
    }

    .AllContacts {
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-left: -130px;
        width: calc(100% + 130px);
    }

    .ContactItem2 {
        width: 194px;
        margin-left: 130px;
        margin-top: 10px;
    }
}

@media (max-width: 1170px) {
    .AllContacts {
        margin-left: -100px;
        width: calc(100% + 100px);
    }

    .ContactItem2 {
        margin-left: 100px;
    }
}

@media (max-width: 1100px) {
    .MainContacts {
        width: calc(100% - 14px);
        justify-content: flex-start;
    }

    .ContactItem {
        width: calc(50% - 18px);
        margin-left: 0 !important;
    }

    .AllContacts {
        margin-left: 0;
        width: 100%;
    }

    .ContactItem2 {
        width: calc(50% - 18px);
        margin-left: 0;
    }
}

@media (max-width: 850px) {
    .ContactsInfo {
        margin-top: 26px;

        h2 {
            font-size: 20px;
        }
    }

    .ContactsTable {
        width: calc(100% - 20px);
        padding: 10px;
    }
}

@media (max-width: 570px) {
    .MainContacts {
        width: calc(100% - 32px);
        flex-direction: column;
        margin-bottom: 24px;
    }

    .ContactItem+.ContactItem {
        margin-left: 0;
        margin-top: 10px;
    }

    .AllContacts {
        flex-direction: column;
    }

    .ContactItem2+.ContactItem2 {
        margin-top: 28px;
    }
}