.HelpInfo {
    margin-top: 42px;

    h2 {
        font-size: 36px;
        font-weight: 700;
        color: #020202;
        margin: 0 0 16px 0;
    }

    h4 {
        font-size: 18px;
        font-weight: 700;
        margin: 0 0 12px 0;
        color: #121212;
    }

    p {
        font-size: 16px;
        font-weight: 500;
        line-height: 160%;
        color: #121212;
        margin: 0 0 12px 0;
    }

    .PoizonIs {
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        line-height: 160%;
        color: #121212;
        margin: 0 0 12px 0;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: double 1px transparent;
        background-image: linear-gradient(white, white),
            linear-gradient(to left, #D9D9D900 10%, #D9D9D9 50%, #D9D9D900 90%);
        background-origin: border-box;
        background-clip: content-box, border-box;

        span {
            margin: 21px 0;
            display: block;
            max-width: 1037px;
            width: 78%;
            text-align: center;
        }
    }

    .HListItem {
        color: #121212BF;
    }
}

.HelpImg {
    width: 100%;
    max-height: 498px;
    min-height: 182px;
    height: 26vw;
    background-color: #D9D9D9;
}

.MBHelp {
    margin-bottom: 20px !important;
}

.MTHelp {
    margin-top: 42px !important;
}

@media (max-width: 850px) {
    .HelpImg {
        height: 35vw;
    }
}

@media (max-width: 775px) {
    .HelpInfo {
        margin-top: 26px;

        h2 {
            font-size: 20px;
        }

        h4 {
            font-size: 18px;
        }

        p {
            font-size: 12px;
        }
    }

    .MBHelp {
        margin-bottom: 16px !important;
    }

    .MTHelp {
        margin-top: 26px !important;
    }
}