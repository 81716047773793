@use '../../base.scss';

.FilterBox {
    width: 100vw;
    height: fit-content;
    min-height: calc(100svh + 2px);
    max-height: calc(100svh + 2px);
    background-color: #161616BD;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
    transition: opacity .2s linear;
    overflow-y: scroll;
    overscroll-behavior: none;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.FilterBoxHide {
    opacity: 0;
}

.FilterHeight {
    display: flex;
    flex-direction: column;
}

.FilterContainer {
    width: 200px;
    min-height: calc(100svh - 52px);
    height: calc(100svh - 52px);
    // height: 100%;
    overflow: scroll;
    padding: 26px 20px;
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform .2s linear;

    &::-webkit-scrollbar {
        width: 0;
        display: none;
    }
}

.FilterContainerShort {
    min-height: calc(100svh - 52px - 64px);
    height: calc(100svh - 52px - 64px);
}

.SortFilter {
    position: fixed;
    width: calc(100% - 40px);
    padding: 5px 20px 26px 20px;
    bottom: 0;
    right: 0;
    background-color: white;
    box-shadow: 0 -5px 5px white;
}

.FilterHide {
    transform: translate(100%, 0);
}

.FilterTop {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    span {
        font-size: 18px;
        font-weight: 700;
        color: #020202;
    }
}

.FilterCheck {
    width: 18px;
    height: 18px;
    background-color: #f5f5f5;
    border-radius: 4px;
    margin-right: 12px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        width: 14px;
        height: 14px;
        background-color: #121212;
        border-radius: 2px;
        left: calc(50% - 7px);
        top: calc(50% - 7px);
        opacity: 0;
        transition: opacity 0.3s ease;
    }
}

.CheckedFilter {
    &::before {
        opacity: 1 !important;
    }
}

.FilterSave {
    margin-top: 32px;
    height: 38px;
    min-height: 38px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.FilterDrop,
.FilterAdd {
    padding: 0 13px;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    @include base.btn();
}

.FilterDrop {
    background-color: #f1f1f1;
    color: #121212;
}

.FilterAdd {
    background-color: #121212;
    color: white;
}


.FilterMenu {
    width: 100%;
}

.FilterMenu+.FilterMenu {
    margin-top: 38px;
}

.FilterMenuItem {
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #020202;
    cursor: pointer;

    img {
        width: 8px;
        height: 8px;
    }

    &.Inactive {
        opacity: 0.5;
        pointer-events: none;
    }
}

.FilterMenuNoBrand {
    display: flex;
    align-items: center;
    margin-top: 24px;

    img {
        width: 10px;
        margin-right: 6px;
    }

    span {
        width: calc(100% - 10px - 6px);
        font-size: 8px;
        font-weight: 500;
        color: #020202;
        opacity: 0.8;
    }
}

.FilterMenuText {
    width: calc(100% - 20px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.FilterSetCount {
    min-width: 8px;
    width: fit-content;
    padding: 0 4px;
    height: 16px;
    border-radius: 16px;
    background-color: #020202;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 700;
}

.FilterMobile {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transform: translate(100%, 0);
    transition: transform .3s ease;
}

.FilterMobileShow {
    transform: translate(0, 0);
}

@media (max-width: 1280px) {
    .FilterTop {
        span {
            font-size: 14px;
        }
    }

    .FilterClose {
        display: flex;
        align-items: center;

        img {
            width: 14px;
            height: 14px;
        }
    }
}

@media (min-width: 850px) {
    .FilterBox {
        display: none;
    }
}